.inspect {
}

.loadingIndicator {
    width: 100%;
    height: 2px;
}
.labelMapContainer {
    height: 100%;
}
.canvasContainer {
    width: 100%;
    height: 100%;
    min-height: 800px;
}
