.overlay {
    padding: 5px;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 200;
    top: 0;
    right: 0;
    position: absolute;
    border-radius: 0;
    min-width: 350px;
    .control {
        button {
            display: block;
            margin: 1px;
            padding: 0;
            color: white;
            font-size: 1.14em;
            font-weight: bold;
            text-decoration: none;
            text-align: center;
            height: 1.375em;
            width: 1.375em;
            line-height: .4em;
            background-color: rgba(0,60,136,0.5);
            border: none;
            border-radius: 2px;

            &.action {
                font-size: 13px;
                display: inline-flex;
                width: auto;
                padding: 10px;
                height: auto;
                cursor: pointer;
            }
            &.active {
                background-color: rgba(0, 60, 136, 0.9);
            }
            &.warn {
                color: #ff0;
                background-color: rgba(0, 60, 136, 0.5);
            }
            &.expanded {
                span {
                    transform: rotate(0);
                    display: inline-block;
                    position: relative;
                    bottom: 3px;
                }
            }
            &.collapsed {
                span {
                    transform: rotate(-90deg);
                    display: inline-block;
                    position: relative;
                    right: 3px;
                }
            }
        }
    }
    .combo-box-container {
        display: flex;
        .MuiOutlinedInput-root {
            border-radius: 0;
            padding: 5px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: #000;
        }
    }
    .combo-info {
        //line-height: 46px;
        line-height: 20px;
        margin-left: 10px;
        font-size: 20px;
        cursor: pointer;
        padding: 5px 15px 5px 10px;
    }
    div {
        .autosave, .snap, .wheel-zoom {
            display: inline-flex;
            margin-left: 0px;
            align-items: center;
            vertical-align: middle;
        }
    }
    .tip {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
    }
    .ap {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        margin-left: 1em;
    }
    .back {
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        vertical-align: middle;
        margin-left: 1em;
    }
    .MuiAutocomplete-inputRoot {
        padding: 0;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0;
    }
    .MuiAutocomplete-popper {
        .MuiAutocomplete-option {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 2em;
            .label-option {
                line-height: 1;
                span {
                    line-height: 1;
                }
                .pilot-square {
                    display: inline-block;
                    font-size: 28px;
                    margin-left: -1em;
                    width: 1em;
                }
            }
        }
    }
}
.control {
    button {
        display: block;
        margin: 1px;
        padding: 0;
        color: white;
        font-size: 1.14em;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        height: 1.375em;
        width: 1.375em;
        line-height: .4em;
        background-color: rgba(0,60,136,0.5);
        border: none;
        border-radius: 2px;

        &.action {
            font-size: 13px;
            display: inline-flex;
            width: auto;
            padding: 10px;
            height: auto;
            cursor: pointer;
        }
        &.active {
            background-color: rgba(0, 60, 136, 0.9);
        }
        &.warn {
            color: #ff0;
            background-color: rgba(0, 60, 136, 0.5);
        }
        &.expanded {
            span {
                transform: rotate(0);
                display: inline-block;
                position: relative;
                bottom: 3px;
            }
        }
        &.collapsed {
            span {
                transform: rotate(-90deg);
                display: inline-block;
                position: relative;
                right: 3px;
            }
        }
    }
}

.comboBox {
    //display: flex;
}
