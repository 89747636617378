.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.header {
    .sortable {
        cursor: pointer;
    }
    .up::after {
        content: '🠕';
    }
    .down::before {
        content: '🠗';
    }
}
.filters {
   input {
       line-height: 12px;
   } 
   .filter {
       position: relative;
       .filterClear {
           position: absolute;
           top: 10px;
           right: 16px;
           cursor: pointer;
       }
    }
}
.list {
    border-collapse: collapse;
    width: 100%;
    max-width: 1400px;
    th {
        text-align: left;
        padding: 2px 10px;
    }
    td {
        padding: 2px 10px;
        &.case_id {
            border: 1px solid transparent;
        }
        &.memo {
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;
            &.brief {
                white-space: nowrap; 
                text-overflow: ellipsis;
                overflow: hidden;
                height: 1em;
                max-width: 908px;
                cursor: pointer;
            }
            &.detail {
                border: 1px dashed #ff0000;
            }
            &:empty {
                cursor: text;
            }
            .memo_ellipsis {
            }
        }
    }
}
.row {
    max-width: 100%;
    &:nth-child(2n+1) {
        background-color: #ececec;
    }
    &:nth-child(2n+1) {
        background-color: #ececec;
    }
    
}
.expand_more {
    float: right;
    cursor: pointer;
    padding: 2px 5px;
    user-select: none;
}

.value {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.button {
  appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.textbox {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 8px;
}

.button:hover, .button:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.button:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.asyncButton {
  composes: button;
  position: relative;
  margin-left: 8px;
}

.asyncButton:after {
  content: "";
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s
}

.crop_selection {
    position: fixed;
    top: 5em;
    right: 1em;
    z-index: 3000;
    width: 540px;
    border: 1px solid #999;
    padding: 10px;
    background: #fff;
    overflow: auto;
    max-height: 800px;
    font-size: 12px;
    table tbody tr td {
        vertical-align: middle;
    }
    .crop_remove {
        cursor: pointer;
    }
    .crop_name input {
        width: 4em;
    }
    .handle_bar {
        width: 100%;
        background:
            linear-gradient(27deg, #959595 5px, transparent 5px) 0 5px,
            linear-gradient(207deg, #959595 5px, transparent 5px) 10px 0px,
            linear-gradient(27deg, #aaa 5px, transparent 5px) 0px 10px,
            linear-gradient(207deg, #aaa 5px, transparent 5px) 10px 5px,
            linear-gradient(90deg, #9b9b9b 10px, transparent 10px),
            linear-gradient(#9d9d9d 25%, #9a9a9a 25%, #9a9a9a 50%, transparent 50%, transparent 75%, #a4a4a4 75%, #a4a4a4);
        background-color: #939393;
        background-size: 20px 20px;
        height: 24px;
        cursor: move;
    }
}

.pointer {
    cursor: pointer;
}
.crop_preview {
    position: absolute;
    z-index: 3500;
    background-color: #ffffff;
    min-width: 120px;
    min-height: 120px;
    .preview_placeholder {
        border: 1px solid #000;
        position: absolute;
        right: 50%;
        transform: translate(50%, 0);
    }
    img.preview_image {
        border: 1px solid #000;
        width: 100%;
    }
}
.cozy {
    width: 1%;
    white-space: nowrap;        
}
.lock {
    text-align: center;
}
.slide_thumbnail {
    cursor: pointer;
    user-select: none;
}
.section_name {
    padding: 0 5px;
}
.additional_nav {
    position: absolute;
    height: 50px;
    top: 0px;
    right: 320px;
    line-height: 50px;
}
.queue_preview {
    position: absolute;
    height: 50px;
    top: 0px;
    right: 250px;
    line-height: 50px;
    a {
        color: #777;
        font-weight: bold;
        &:hover {
            color: #333;
        }
    }
    button {
        cursor: pointer;
    }
    .queue {
        position: absolute;
        z-index: 5000;
        background-color: #fff;
        border: 1px solid black;
        line-height: 1.2;
        padding: 10px;
        font-size: 12px;
        width: 160px;
        &.hidden {
            display: none;
        }
        ol {
            margin: 0;
            padding: 10px 3px;
            padding-left: 30px;
        }
    }
}
.crop_info {
    th {
        text-align: center;
    }
    td {
        text-align: center;
    }
}
.user_manual {
    position: absolute;
    top: 0px;
    line-height: 50px;
    right: 0px;
    margin-left: 20px;
    width: 60px;
    color: #777;
    font-weight: bold;
    &:hover {
        color: #333;
    }
}
.help_tooltip {
    position: absolute;
    right: 0;
    z-index: 5000;
    background-color: #fff;
    border: 1px solid black;
    line-height: 1.2;
    font-size: 12px;
    padding: 10px;
    &.hidden {
        display: none;
    }
}
