.inspect {
}
.canvasContainer {
    padding-top: 40px;
    position: relative;
}

.flatmap {
    margin-top: 40px;
}
.loadingIndicator {
    width: 100%;
    height: 2px;
}
svg {
}
