* {
    box-sizing: inherit;
}
html, body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

$gap: 5px;


.app {
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.center-modal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

nav {
    border-bottom: 1px solid black;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 5px 10px;
    margin: 0 20px;
    .login {
        grid-column: 9 / span 4;
        text-align: right;
    }
    ul {
        grid-column: 1 / span 8;
        list-style-type: none;
        padding-left: 0;
        li {
            list-style: none;
            display: inline-block;
        }
    }
}

.crumb {
    display: inline-block;
    a::after {
        display: inline-block;
        color: #000;
        content: '>';
        font-size: 80%;
        font-weight: bold;
        padding: 0 3px;
    }
}
a, a:visited {
    color: auto;
}
.Toastify__toast {
    box-shadow: none;
    border: 1px solid #ccc;
}
.mirror {
    transform: scaleX(-1);
    display: inline-block;
}
.error {
    color: red;
}
.form-error {
    margin: 10px 0;
}
.peek-processing-queue {
    cursor: pointer;
}
svg {
    g.cell {
        rect {
            stroke-width: 1px;
        }
    }
}
.tooltip {
    width: 20em;
}
.histology-layer {
    //filter: blur(4px);
}
