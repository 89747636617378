#root, .app, .main {
    height: 100%;
    font: 14px "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.utility {
    .MuiAutocomplete-inputRoot {
        padding: 0;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0;
    }
    .MuiAutocomplete-popper {
        .MuiAutocomplete-option {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 2em;
            .label-option {
                line-height: 1;
                span {
                    line-height: 1;
                }
                .pilot-square {
                    display: inline-block;
                    font-size: 28px;
                    margin-left: -1em;
                    width: 1em;
                }
            }
        }
    } 
}
.ol-overviewmap {
    bottom: 2.5em;
}
.label-manage {
    .MuiDialog-paperScrollPaper {
        height: calc(100% - 64px);
    }
}
.label-meta, .label-summary {
    /*
    top: 50%;
    left: 50%;
    margin-left: -50%;
    margin-top: -50%;
    width: 80vmax;
    height: 80vmax;
    background-color: #fff;
    */
    border: 1px solid;
    color: #333;
    font-size: 14px;
    .title {
        padding: 16px 30px 5px 30px;
        border-bottom: 1px solid #aaa;
        margin-bottom: 20px;
        .main-title {
            font-size: 18px;
        }
        .sub-title {

        }
    }
    .content-container {
        margin-left: 30px;
        margin-right: 30px;
        padding-bottom: 30px;
    }
    .note {
        position: absolute;
        z-index: 20;
        bottom: 20px;
        font-size: 12px;
    }
    .MuiPaper-elevation24 {
        box-shadow: none;
        border-radius: 0;
        max-width: none;
        width: 80vw;
        .MuiDialogContent-root {
            padding: 0;
        }
    }
}
.labelmap-case-list {
    h4 {
        font-size: 24px;
        font-weight: bold;
    }
    .case-label {
        display: inline-block;
    }
    .MuiAutocomplete-root,.MuiAutocomplete-hasClearIcon,.MuiAutocomplete-hasPopupIcon {
        display: inline-block;
        width: 250px;
        vertical-align: middle;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
        padding-top: 0;
        padding-bottom: 0;
    }
    .case-list-with-limit {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        span {
            padding-left: 0px;
            padding-right: 1em;
        }
    }
}
.MuiAutocomplete-popper {
    .MuiAutocomplete-listbox {
        max-height: calc(100vh - 300px);
    }
}
.label-summary {
    button {
        cursor: pointer;
    }
    .labels-panel {
        position: relative;
        height: 100%;
        display: grid;
        grid-template-columns: 2fr 3fr 2fr;
        gap: 16px;
        .label-candidates {
            grid-column-start: 1;
        }
        .add-label {
            position: absolute;
            bottom: 120px;
        }
        .export-spreadsheet {
            position: absolute;
            bottom: 80px;
        }
        //right: 0;
        //top: 0;
        //z-index: 900;
        section.label-detail {

            &.disabled {
                .detail-wrap {
                    padding: 10px;

                    background-color: #ffeeee;
                    background-image: repeating-linear-gradient(
                        45deg, 
                        #ffd0d0 0, 
                        #ffd0d0 20px, 
                        transparent 20px, 
                        transparent 40px);
                }
            }
            .detail-wrap {
                align-items: top;
                display: grid;
                grid-template-rows: 13em 1fr;
            }
            .meta {
                display: grid;
                position: relative;
                height: 12em;
                .entry {
                    display: grid;
                    align-items: start;
                    grid-template-columns: 5em 3fr;
                    .label {
                    }
                }
                input[type="text"] {
                    /*
                    &:disabled {
                        background-color: #ffeeee;
                        background-image: repeating-linear-gradient(
                            45deg, 
                            #ffd0d0 0, 
                            #ffd0d0 20px, 
                            transparent 20px, 
                            transparent 40px);
                    }
                    */
                    &.label-id {
                        width: 4em;
                    }
                }
                .cases-icon {
                    display: inline-block;
                    padding: 3px;
                    border: 1px solid #000;
                    position: absolute;
                    right: 0px;
                    z-index: 8000;
                }
                .cases {
                    position: absolute;
                    top: 30px;
                    right: 0px;
                    max-width: 20em;
                    background-color: #fff;
                    padding: 5px;
                    border: 1px solid;
                    z-index: 10000;
                }
            }
            .areas {
                display: grid;
                .entry {
                    display: grid;
                    //align-items: center;
                    grid-template-columns: 5em 3fr;
                    .label {
                    }
                    .area-list {
                        .area-box {
                            margin-bottom: 2px;
                            position: relative;
                            span.button {
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 5px;
                                padding: 5px;
                                z-index: 900;
                                cursor: pointer;
                            }
                        }
                    }
                }

            }

        }
        .actions {
            margin-top: 30px;
            button {
                margin-right: 30px;
            }
        }
    }

}
.ol-zoom {
    top: 50%;
} 
.info-window {
    position: absolute;
    z-index: 200;
}

.utility {
    padding: 5px;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 100;
    left: 0em;
    //position: absolute;
    border-radius: 0;
    min-width: 350px;
    .control {
        button {
            display: block;
            margin: 1px;
            padding: 0;
            color: white;
            font-size: 1.14em;
            font-weight: bold;
            text-decoration: none;
            text-align: center;
            height: 1.375em;
            width: 1.375em;
            line-height: .4em;
            background-color: rgba(0,60,136,0.5);
            border: none;
            border-radius: 2px;

            &.action {
                font-size: 13px;
                display: inline-flex;
                width: auto;
                padding: 10px;
                height: auto;
                cursor: pointer;
            }
            &.active {
                background-color: rgba(0, 60, 136, 0.7);
            }
            &.warn {
                color: #ff0;
                background-color: rgba(0, 60, 136, 0.5);
            }
            &.expanded {
                span {
                    transform: rotate(0);
                    display: inline-block;
                    position: relative;
                    bottom: 3px;
                }
            }
            &.collapsed {
                span {
                    transform: rotate(-90deg);
                    display: inline-block;
                    position: relative;
                    right: 3px;
                }
            }
        }
    }
    .combo-box-container {
        display: flex;
        .MuiOutlinedInput-root {
            border-radius: 0;
            padding: 5px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: #000;
        }
    }
    .combo-info {
        //line-height: 46px;
        line-height: 20px;
        margin-left: 10px;
        font-size: 20px;
        cursor: pointer;
        padding: 5px 15px 5px 10px;
    }
    div.autosave {
        display: inline-flex;
        margin-left: 0px;
        align-items: center;
        vertical-align: middle;
    }
    .tip {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
    }
    .ap {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        margin-left: 1em;
    }
    .back {
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        vertical-align: middle;
        margin-left: 1em;
    }
}
.section-memo {
    border: 1px solid #000;
    margin-top: 20px;
    position: absolute;
    z-index: 200;
    top: 30%;
    textarea {
        margin-top: 5px;
        resize: none;
        border: none;
        border-left: 4px solid green;
        &.dirty {
            border-left: 4px solid red;
        }
    }
}    
.hide-all-overlay {
    position: fixed;
    top: 0;
    width: auto;
    margin-left: 20px;
    background: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    text-align: center;
    z-index: 2000;
    
}
.quick-label {
    padding: 5px 5px 9px 5px;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 100;
    left: .5em;
    //margin-top: 150px;
    //top: 450px;
    bottom: 200px;
    
    position: absolute;
    //width: 50px;
    .button-container {
        width: 100%;
        display: flex;
    }
    .hotkey {
        vertical-align: top;
        line-height: 25px;
        margin-right: 10px;
        width: .5em;
        text-align: right;
        kbd {
            margin-top: 0;
            line-height: 30px;
        }
    }
    .quick-button {
        width: 85px;
        height: 26px;
        margin-top: 4px;
        margin-left: 16px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        line-height: 25px;
        align-items: center;
        border: 2px solid transparent;
        &.highlight {
            border: 2px solid black;
        }
    }
}
kbd {
    border:1px solid gray;
    font-size:1.2em;
    box-shadow:1px 0 1px 0 #eee, 0 2px 0 2px #ccc, 0 2px 0 3px #444;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    border-radius:3px;
    margin:2px 3px;
    padding:1px 5px;
    line-height: 40px;
    white-space: nowrap;


}


.Toastify__toast--success {
    border: 1px solid #000 !important;
    background-color: #eeffee !important;
    color: #000 !important;
    .Toastify__toast-body {
        text-align: center;
    }
}
.filmstrip {
    width: 100%;
    white-space: nowrap;
    height: 135px;
    position: absolute;
    overflow-x: scroll;
    overflow-y: hidden;
    bottom: 0;
    z-index: 1;
    a.active {
        cursor: initial;
        .section-thumbnail {
            border: 4px solid #ff2020;
        }
    }
    .section-thumbnail {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
    }
    .section-label {
        position: absolute;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        text-align: center;
        height: 1.75em;
        padding-top: 0;
        padding-bottom: .5em;
        z-index: 2;
    }

}
.main {
    padding-bottom: 140px;
}
.summary-button {
    position: absolute;
    right: 120px;
    top: 0;
    z-index: 900;
    cursor: pointer;
}
.labels-button {
    position: absolute;
    right: 0px;
    top: 0;
    z-index: 900;
    cursor: pointer;
}
.summary-panel {
    position: relative;
    //right: 0;
    //top: 0;
    //z-index: 900;
    .caption-section {
        font-size: 14px;
        color: #000;
    }
    line.crosshair {
        stroke: #0000ff;
    }
    .label-row {
        font-size: 11px;
    }
    .label-column {
        font-size: 10px;
        cursor: pointer;
    }
    rect {
        cursor: pointer;
    }
    .matrix {
        //max-width: calc(100vw - 400px);
        overflow: auto;
        //padding-bottom: 60px;
    }
    .memo-strike {
        pointer-events: none;
    }
}

.tooltip {
    position: absolute;
    display: none;
    top: 0;
    background-color: #fff;
    border: 1px solid #555;
    padding: 10px;
    text-align: left;
    border-radius: 0;
    color: #000;
    z-index: 2000;
    padding-top: 20px;
    &.label-list {
        padding-top: 10px;
    }
    .stack-index {
        position: absolute;
        top: 2px;
    }
    .close-button {
        float: right;
        margin-top: -17px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
    }
    .label-info {
        margin-top: -10px;
    }
    .ap-value {
        width: 6em;
    }

}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(55, 58, 71, 0.9);
    animation-fill-mode: fowards;
    animation-duration: 0s;
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}
.modal-content {
    //position: absolute;
    background-color: #fff;
    //top: 50%;
    //left: 50%;
    //right: auto;
    //bottom: auto;
    //margin-right: -50%;
    //transform: translate(-50%, -50%);
    max-width: none;
}
.area-box {
    display: block;
    padding: 5px;
    border: 1px solid #001;
}
span.button {
    cursor: pointer;
}
